<template>
<!--  todo complete this after model-pages   -->
  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-dark bg-white" id="sidenavAccordion">
      <div class="sb-sidenav-menu e-bg-red rounded">
        <ul class="nav navbar-nav p-2">
          <template v-if="user.vessel_id == null">
            <div class="text-white pt-4 cursor-pointer" @click="showDashboard = !showDashboard" :class="showDashboard === false ? 'mb-3' : ''">
              <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
              DASHBOARD
              <span v-if="showDashboard" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
              <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
            </div>
            <template v-if="showDashboard">
              <router-link
                v-if="isElite()"
                class="nav-item e-text-white ml-4" :class="{'active': isUnderNumberOfManagement }" :to="{ name: 'NumberOfManagement', params : {
              report_type : 'flag'
            }}">
                <font-awesome-icon icon="th-large" class="force-white-all" style="width: 25px" />
                <small>VESSEL INFO</small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :class="{'active': isUnderAiDashBoard}" :to="{ name: 'AiDashboard', params : {
              chart_module : 'number_of_report'
            }}">
                <font-awesome-icon icon="th-large" class="force-white-all" style="width: 25px" />
                <small>ACCIDENT / INCIDENT</small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'PscDashboard'}">
                <font-awesome-icon icon="th-large" class="force-white-all" style="width: 25px" />
                <small>PSC INSPECTION</small>
              </router-link>
            </template>
            <hr class="my-0" />
          </template>
          <div class="text-white pt-4 cursor-pointer" @click="showReportList = !showReportList" :class="showReportList === false ? 'mb-3' : ''">
            <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
            REPORT LIST
            <span v-if="showReportList" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
            <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
          </div>
          <template v-if="showReportList">
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'Module'}"
                         v-if="isAllowed('menu.reports.accident-incident')">
              <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
              <small>
                ACCIDENT / INCIDENT
              </small>
            </router-link>
            <hr class="ml-4 my-0" />
            <div class="text-white mt-2 ml-3 pl-3 cursor-pointer mt-3" @click="showInspection = !showInspection" :class="showInspection === false ? 'mb-3' : ''">
              INSPECTION
              <span v-if="showInspection" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
              <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
            </div>
            <template v-if="showInspection">
              <router-link class="nav-item e-text-white ml-4" :class="{'active':$route.name ==='PscIndex'}" :to="{ name: 'Psc'}"
                           v-if="isAllowed('menu.reports.psc-inspection')">
                <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
                <small>
                  PSC
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :class="{'active':$route.name ==='RsIndex'}" :to="{ name: 'Rightship'}"
                           v-if="isAllowed('menu.reports.rightship-inspection')">
                <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
                <small>
                  RIGHTSHIP INSPECTION
                </small>
              </router-link>
            </template>
<!--            <br v-else>-->
            <hr class="ml-4 my-0" />
            <div class="text-white mt-2 ml-3 pl-3 cursor-pointer mt-3" @click="showInternalAudit = !showInternalAudit" :class="showInternalAudit === false ? 'mb-3' : ''">
              INTERNAL AUDIT
              <span v-if="showInternalAudit" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
              <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
            </div>
            <template v-if="showInternalAudit">
              <router-link  class="nav-item e-text-white ml-4" :class="{'active':$route.name ==='InternalAudit'}" :to="{ name: 'AuditorList'}"
                            v-if="isAllowed('menu.reports.internal-audit-label.audit-team')">
                <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
                <small>
                  AUDIT TEAM
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :class="{'active': isUnderVesselAudit}" :to="{ name: 'VesselList'}"
                           v-if="isAllowed('menu.reports.internal-audit-label.vessel-audit')">
                <font-awesome-icon icon="ship" class="force-white-all" style="width: 25px" />
                <small>
                  VESSEL AUDIT
                </small>
              </router-link>
              <router-link  class="nav-item e-text-white ml-4" :class="{'active': isUnderVPIStatus}" :to="{ name: 'VPIIntroduction'}"
                            v-if="isAllowed('menu.reports.internal-audit-label.vpi-status')">
                <font-awesome-icon icon="ship" class="force-white-all" style="width: 25px" />
                <small>
                  VPI STATUS
                </small>
              </router-link>
<!--              <router-link  class="nav-item e-text-white ml-4" :class="{'active': isUnderCompanyAudit}" :to="{ name: 'CompanyList'}"-->
<!--                            v-if="isAllowed('menu.reports.internal-audit-label.company-audit')">-->
<!--                <font-awesome-icon icon="briefcase" class="force-white-all" style="width: 25px" />-->
<!--                <small>-->
<!--                  COMPANY AUDIT-->
<!--                </small>-->
<!--              </router-link>-->
            </template>
            <hr class="ml-4 my-0" />
            <a class="nav-item e-text-white e-bg-red-light-3 ml-4 no-hover"
               v-if="isAllowed('menu.reports.master-review')">
              <font-awesome-icon icon="chart-line" class="force-white-all" style="width: 25px;" />
              <small>
                MASTER'S REVIEW AND IMPROVEMENT
              </small>
            </a>
          </template>
          <hr class="my-0" />
          <template v-if="isElite() || isAllowed('menu.company-instruction')">
            <template v-if="user.vessel_id == null">
              <div class="text-white pt-4 cursor-pointer" @click="showCompanyInstruction = !showCompanyInstruction" :class="showCompanyInstruction === false ? 'mb-3' : ''">
                <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
                COMPANY INSTRUCTION
                <span v-if="showCompanyInstruction" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
                <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
              </div>
              <template v-if="showCompanyInstruction">
                <router-link class="nav-item e-text-white ml-4"
                             :to="{ name: 'StaffCiAir', query:{type:'air',t:new Date().getTime()}}"

                             v-if="isAllowed('menu.company-instruction.accident-incident')">
                  <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                  <small>
                    ACCIDENT / INCIDENT
                  </small>
                </router-link>
                <router-link class="nav-item e-text-white ml-4"
                             :to="{ name: 'StaffCiPsc',query:{type:'psc',t:new Date().getTime()}}"
                             v-if="isAllowed('menu.company-instruction.psc-inspection')">
                  <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
                  <small>
                    PSC INSPECTION
                  </small>
                </router-link>
                <router-link class="nav-item e-text-white ml-4"
                             :to="{ name: 'StaffCiOe',query:{type:'ia_oe',t:new Date().getTime()}}"
                             v-if="isAllowed('menu.company-instruction.vessel-audit')">
                  <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
                  <small>
                    VESSEL AUDIT
                  </small>
                </router-link>
              </template>
            </template>
            <template v-else>
              <div class="text-white pt-4 cursor-pointer" @click="showCompanyInstruction = !showCompanyInstruction" :class="showCompanyInstruction === false ? 'mb-3' : ''">
                <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
                COMPANY INSTRUCTION
                <span v-if="showCompanyInstruction" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
                <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
              </div>
              <template v-if="showCompanyInstruction">
                <router-link class="nav-item e-text-white ml-4" :to="{ name: 'MasterCiAir',query:{type:'air',t:new Date().getTime()}}">
                  <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                  <small>
                    ACCIDENT / INCIDENT
                  </small>
                </router-link>
                <router-link class="nav-item e-text-white ml-4" :to="{ name: 'MasterCiPsc',query:{type:'psc',t:new Date().getTime()}}">
                  <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                  <small>
                    PSC INSPECTION
                  </small>
                </router-link>

                <router-link class="nav-item e-text-white ml-4" :to="{ name: 'MasterCiOe',query:{type:'ia_oe',t:new Date().getTime()}}">
                  <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                  <small>
                    VESSEL AUDIT
                  </small>
                </router-link>
              </template>

            </template>
            <hr class="my-0" />
          </template>
          <template v-if="isAllowed('menu.vessels')">
            <router-link class="nav-item e-text-white" :to="{ name: 'Vessel'}"
                         v-if="user.vessel_id == null">
              <font-awesome-icon icon="ship" class="force-white-all" style="width: 25px" />
              VESSELS
            </router-link>
            <router-link class="nav-item e-text-white" :to="`/vessels/${user.vessel_id}/particular`"
                         v-else>
              <font-awesome-icon icon="ship" class="force-white-all" style="width: 25px" />
              VESSEL
            </router-link>
            <hr class="my-0" />
          </template>

<!--          <template v-if="isAllowed('menu.vessels')">-->
<!--            <router-link class="nav-item e-text-white" :to="{ name: 'Company'}">-->
<!--              <font-awesome-icon icon="building" class="force-white-all" style="width: 25px" />-->
<!--              COMPANIES-->
<!--            </router-link>-->
<!--            <hr class="my-0" />-->
<!--          </template>-->

          <template v-if="isElite()">
            <router-link class="nav-item e-text-white" :to="{ name: 'ShipsPI'}"
                         v-if="isAllowed('menu.ships-pi')">
              <font-awesome-icon icon="info-circle" class="force-white-all" style="width: 25px" />
              SHIP'S PI
            </router-link>
            <hr class="my-0" />
            <div class="text-white pt-4 cursor-pointer" @click="showSettings = !showSettings">
              <font-awesome-icon icon="cogs" class="force-white-all" style="width: 25px" />
              SETTINGS
              <span v-if="showSettings" class="force-white-all">
                <font-awesome-icon icon="chevron-down"/>
              </span>
              <span v-else class="force-white-all">
                <font-awesome-icon icon="chevron-right"/>
              </span>
            </div>
            <template v-if="showSettings && isElite()">
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingAnnouncement'}"
                           v-if="isAllowed('menu.settings.user')">
                <font-awesome-icon icon="bullhorn" class="force-white-all" style="width: 25px" />
                <small>
                  NEWS/ANNOUNCEMENT
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsUsers'}"
                           v-if="isAllowed('menu.settings.user')">
                <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
                <small>
                  USERS
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsCurrencies'}"
                           v-if="isAllowed('menu.settings.currency')">
                <font-awesome-icon icon="money-bill" class="force-white-all" style="width: 25px" />
                <small>
                  CURRENCIES
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsEvents'}"
                           v-if="isAllowed('menu.settings.event')">
                <font-awesome-icon icon="calendar" class="force-white-all" style="width: 25px" />
                <small>
                  EVENTS
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsCauses'}"
                           v-if="isAllowed('menu.settings.cause')">
                <font-awesome-icon icon="car-crash" class="force-white-all" style="width: 25px" />
                <small>
                  CAUSES
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SisterShip'}"
                           v-if="isAllowed('menu.settings.sister-ship')">
                <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
                <small>
                  SISTER SHIP
                </small>
              </router-link>
              <!--            v-if="isAllowed('menu.settings.sister-ship')"-->
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'RelatedParty'}">
                <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
                <small>
                  RELATED PARTIES
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineMaker'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  ENGINE MAKER
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineType'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  ENGINE TYPE
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineDgMaker'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  ENGINE D/G MAKER
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineDgType'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  ENGINE D/G TYPE
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineTcMaker'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  ENGINE T/C MAKER
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineTcType'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  ENGINE T/C TYPE
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineAuxMaker'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  AUX BOILER MAKER
                </small>
              </router-link>

              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineAuxType'}" v-if="isElite">
                <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
                <small>
                  AUX BOILER TYPE
                </small>
              </router-link>
            </template>
          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: 'MainSidebar',
  data() {
    return {
      showDashboard : true,
      showReportList : true,
      showInspection : true,
      showInternalAudit : true,
      showCompanyInstruction : true,
      showSettings : true
    }
  },
  methods: {
    ...mapActions(['pullUserDetails']),
    async vesselOverview(vesselId) {
      await this.$router.push({
        name: "VesselParticular",
        params: {id: vesselId},
      });

    },
    isActiveRouteCompanyInstruction(routeName,type){
      let currentRouteName = this.$router.currentRoute.name
      if(currentRouteName==routeName){
        if(!!this.$route.query.type) return this.$route.query.type==type
      }
      return false;
    },

  },
  mixins: [permissionMixin],

  created () {
    this.pullUserDetails()
  //   todo call the permission getters to backend here
  },
  computed: {
    ...mapGetters(['user']),
    isUnderVesselAudit() {
      return this.$route.name === 'InternalAudit' ||
        this.$route.name === 'ObjectiveEvidence' ||
        this.$route.name === 'VesselAuditDue' ||
        this.$route.name === 'Audit' ||
        this.$route.name === 'internalAuditReport' ||
        this.$route.name === 'NonConformity' ||
        this.$route.name === 'NcrMainDetails' ||
        this.$route.name === 'VesselAuditRecordList' ||
        this.$route.name === 'ObjectiveEvidenceList' ||
        this.$route.name === 'ObjectiveEvidenceDetail' ||
        this.$route.name === 'OEMainDetails' ||
        this.$route.name === 'OEAttachments' ||
        this.$route.name === 'IaCompanyInstructions' ||
        this.$route.name === 'IaCorrectiveActionReport' ||
        this.$route.name === 'IAShipParticular';
    },
    isUnderCompanyAudit() {
      return this.$route.name === 'CompanyInternalAudit' ||
        this.$route.name === 'CompanyObjectiveEvidence' ||
        this.$route.name === 'CompanyAuditDue' ||
        this.$route.name === 'CompanyAudit' ||
        this.$route.name === 'CompanyInternalAuditReport' ||
        this.$route.name === 'CompanyNonConformity' ||
        this.$route.name === 'CompanyNcrMainDetails' ||
        this.$route.name === 'CompanyVesselAuditRecordList' ||
        this.$route.name === 'CompanyObjectiveEvidenceList' ||
        this.$route.name === 'CompanyObjectiveEvidenceDetail' ||
        this.$route.name === 'CompanyOEMainDetails' ||
        this.$route.name === 'CompanyOEAttachments' ||
        this.$route.name === 'CompanyIaCompanyInstructions' ||
        this.$route.name === 'CompanyIaCorrectiveActionReport' ||
        this.$route.name === 'CompanyIAShipParticular';
    },
    isUnderVPIStatus() {
      return this.$route.name === 'VPIIntroduction' ||
        this.$route.name === 'VPIGraph' ||
        this.$route.name === 'VPI';
    },
    isUnderAiDashBoard() {
      return this.$route.name === 'AiDashboard';
    },
    isUnderNumberOfManagement() {
      return this.$route.name === 'NumberOfManagement'
    }
  }
}
</script>

<style scoped lang="scss">
.nav > a {
  margin: .35rem 0;
  padding: .5rem;
  border-radius: 5px;
  text-decoration: none;
  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.active {
  background-color: white;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}
.router-link-active {
  background-color: white;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}
.no-hover {
  pointer-events: none;
}

.cursor-pointer{
  cursor: pointer !important;
}
</style>
